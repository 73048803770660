






















import Vue from 'vue';
import Component from 'vue-class-component';
import { Props, Global } from '@/store';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class InputBox extends Vue{
    @Global.State('lang') lang;
    @Global.State('readonly_user') readonly_user;

    @Prop() edit_mode;
    @Prop() input;
    @Prop() input_connected;
    
    get connected(){
        return this.input.configuration && (this.input.configuration.toLowerCase().includes('1=tripped') || this.input.configuration.toLowerCase().includes('1=off'))
            ? !this.input_connected
            : this.input_connected;
    }

}

